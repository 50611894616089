.slots-container {
  display: flex; 
  height: 100%;
  margin-top: 50px; 
  align-items: center; 
  flex-direction: column;
}

.calls-box {
  border: 1px solid black; 
  border-radius: 10px; 
  width: 400px;
  padding: 10px;
}

@media only screen and (max-width: 400px) {
  .calls-box {
    width: 100%;
  }
}